import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import Main from "./components/Main";
import NotFound from "./components/NotFound";
import Header from './components/Header';
import Footer from "./components/Footer";
import CallOrder from "./components/CallOrder";
import Catalog from "./components/Catalog";
import CatalogElemPage from "./components/CataloguePage/CatalogElemPage";
import AdminPanel from "./components/Admin/AdminPanel";


const ServerAddres = 'https://server.apriorishop.ru'

function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const CustomTitle = ({ title }) => {
    React.useEffect(() => {
      document.title = title;
    }, [title]);

    return null;
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/admin" element={
            <>
              <CustomTitle title='Apriori - ADMIN' />
              <AdminPanel />
            </>
        } />
        <Route path="/" element={
          <>
            <CustomTitle title="Априори" />
            <Main />
          </>
        } />
        <Route path="/catalog/:category/" element={
          <>
            <CustomTitle title="Априори - Каталог" />
            <Catalog />
          </>
        } />
        <Route path="/catalog/" element={
          <>
            <CustomTitle title="Априори - Каталог" />
            <Catalog />
          </>
        } />
        <Route path="/catalog/product/:item/" element={
          <>
            <CustomTitle title="Априори - Каталог" />
            <CatalogElemPage />
          </>
        } />
        <Route path="*" element={
          <>
            <CustomTitle title="404 - страница не найдена" />
            <NotFound />
          </>
        } />
      </Routes>
      <CallOrder />
      <Footer />
    </>
  );
}

export default App;
export {ServerAddres}