import React from 'react'
import MainFirst from './MainPage/MainFirst'
import MainSecond from './MainPage/MainSecond'
import MainThird from './MainPage/MainThird'
import MainFourth from './MainPage/MainFourth'
import MainSixth from './MainPage/MainSixth'

export default function Main() {
  return (
    <main id='main-page'>
      <MainFirst/>
      <MainSecond/>
      <MainThird/>
      <MainFourth/>
      <MainSixth/>
    </main>
  )
}
