const catalog_descriptions = [
    {
        type:'scrubbers',
        batteryType:'Батарея',
        dirtyWaterVolume:'Объем бака для грязной воды',
        workWidthBrushes:'Рабочая ширина щеток, мм',
        motorPowerBrushes:'Мощность мотора щетки, Вт',
        pressureBrush:'Давление прижима щетки, кг',
        driveMotorPower:'Мощность мотора привода',
        machineVoltage: 'Напряжение машины, В',
        oneChargeTime:'Время работы на одном заряде, ч',
        dimensions:'Габаритные размеры, ДхШхВ',
        cleanWaterVolume:'Объем бака для чистой воды',
        efficiency:'Теоретическая производительность, м²/ч',
        brushDiameterQuantity:'Диаметр щётки х кол-во, мм',
        brushRotationSpeed: 'Скорость вращения щетки, об/мин',
        widthDrainageBeam: 'Ширина водосборной балки, мм',
        vehicleSpeed: 'Скорость машины, км/ч',
        batteryAndQuantity:'Батарея х кол-во',
        noiseLevel:'Уровень шума, Дб',
        weightWithBattery:'Вес с батареями, кг'
    },
    {
        type:'detergents',
        workTemp:'Рабочая температура, °C',
        substanceType: "Тип химии",
        workTime:'Время мойки, мин',
        ph:'Кислотный показатель pH',
    },
    {
        type:'food-equipment',
        
    },
    {
        type:'hp-washers',
        powerElectric:'Питание, (ф/В/Гц)',
        powerGas:'Питание',
        efficiency:'Производительность, (л/мин)',
        pressure:'Рабочее давление, (бар)',
        powerConsumption:'Потребляемая мощность, (кВт)',
        maxTemperature:'Макс. температура воды на входе, (°C)',
        dimensions:'Размеры ДхШхВ, (мм)',
        power:'Мощность, кВт',
        rpm:'Оборотов двигателя, об/мин',
        pompConstruction:"Конструкция помпы",
        pompMaterial:"Материал помпы",
        motorPomp:"Материал мотора"
    },
    {
        type:"ge-lawn-mower",
        "lawn-mower-type": "Тип",
        "engine-brand": "Марка двигателя",
        "engine-model": "Модель двигателя",
        "engine-type": "Тип двигателя",
        "engine-power": "Мощность двигателя, л.с.",
        "cylinder-volume": "Объем двигателя, см³",
        "cylinder-count": "Количество цилиндров",
        "cooling-system": "Охлаждение",
        "fuel-volume": "Объем топливного бака, л",
        "cutting-width": "Ширина кошения, см",
        "cutting-height": "Высота стрижки",
        "blade-count": "Количество ножей",
        "max-torque":"Максимальный крутящий момент",
        "max-slope": "Максимально допустимый уровень склона, %",
        "turning-radius": "Радиус поворота, см",
        "differential-lock": "Блокировка дифференциала",
        "cruise-control": "Круиз-контроль",
        "drive": "Привод",
        "transmission": "Тип трансмиссии",
        "speed": "Скорость, км/ч",
        "grass-collector": "Травосборник",
        "rear-discharge": "Задний выброс",
        "side-discharge": "Боковой выброс",
        "mulching": "Мульчирование",
        "cab": "Кабина",
        "wheels": "Колеса",
        "kit": "Комплект",
        "application": "Применение",
        "dimensions": "Габариты",
        "weight": "Вес, кг",
        "front-wheels": "Передние колеса",
        "rear-wheels": "Задние колеса",

        "processing-area":"Площадь обработки, м²",
        "height-adjustment":"Центральная регулировка высоты стрижки",
        "collector-capacity":"Емкость травосборника",
        "box-dimension":"Габариты коробки",
        "body-material": "Материал корпуса",
        "handle": "Рукоятка",
        "collector-lift-height": "Высота подъема травосборника",
        "deck-drive": "Привод деки",
        "starter":"Стартер",
        "max-pressure": "Максимальное давление",
        "blade-type": "Тип ножа",
        "connect-module":"Connect модуль (GPS, GSM)	",
        "battery-type": "Тип аккумулятора",
        "autonomous-runtime": "Время автономной работы",
        "blade-type":"Тип ножа",
        "max-work-zones": "Максимальное количество рабочих зон",
        "charging-method": "Способ подзарядки",
        "grass-detection-sensor": "Датчик распознования травы",
        "lift-tilt-sensor": "Датчик подъема/опрокидывания",
        "bluetooth-receiver": "Bluetooth-ресивер",
        "eco-mode": "Экономичный режим",
        "emergency-start-stop-button": "Экстренная кнопка пуска/останова",
      "protection-level": "Уровень защиты",
      "perimeter-wire": "Провод периметра",
      "noise-level": "Уровень шума",
    },
    {
        type:'vacuums',
        "power":"Мощность, Вт",
        "tank-volume":"Объем бака, Л",
        "voltage":"Напряжение, В",
        "underpressure":"Разрежение, mBar",
        "air-flow":"Расход воздуха, л/с",
        "cable-lenght":"Кабель, м",
        "dimensions":"Размер коробки, мм",
        "weight":"Вес, кг"
    }
      
]
export {catalog_descriptions}