// src/hooks/useServerStatus.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ServerAddres } from '../App';

const useServerStatus = () => {
  const [isConnect, setIsConnect] = useState(false);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        await axios.get(`${ServerAddres}/api/ping`);
        setIsConnect(true);
      } catch (error) {
        setIsConnect(false);
      }
    };

    checkServerStatus();

    // Optionally, you can add an interval to continuously check the server status
    const interval = setInterval(checkServerStatus, 5000); // checks every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return isConnect;
};

export default useServerStatus;
