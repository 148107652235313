import React, { useState } from 'react';
import arrow from '../../content/right-arrow.svg';
import fd50 from '../../content/catalog-img/fd50.jpg'
import fd85 from '../../content/catalog-img/fd85.jpg'
import cany118 from '../../content/cany118.png'
import can804 from '../../content/can804.png'
import { NavLink } from 'react-router-dom';


export default function MainSixth() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        { id: 1,title: 'WELLLAND F50PL', link:'catalog/product/4__wellland__f50pl',price: 359000, image: fd50},
        { id: 2,title: 'WELLLAND FD85', link:'catalog/product/14__wellland__fd85',price: 1017000 , image: fd85},
        { id: 3,title: 'NG BASE FOAM CL', link:'catalog/product/100__novelhim__ng%20base%20foam%20cl', image: cany118 },
        { id: 4,title: 'NG BASE CIP ACID', link:'catalog/product/96__novelhim__ng%20base%20cip%20acid', image: can804}
    ];

    const handlePrevSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    };

    const handleNextSlide = () => {
        setCurrentIndex(prevIndex => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className='main-top-sales'>
            <div className="main-top-sales-cont">
                <div className="main-top-sales-text">
                    <h2>популярное</h2>
                    <h1>хиты продаж</h1>
                </div>
                <div className="slider-cont-elem">
                    <div className='left-slide-button' onClick={handlePrevSlide}><img src={arrow} alt="Left Arrow" /></div>
                    <div className='main-top-sales-list'>
                        {slides.map((slide, index) => (
                            
                            <div key={slide.id} className="slide-item" style={{transform: `translateX(${(index - currentIndex) * 100}%)`}}>
                                <img src={slide.image} alt="" />
                                <div className='slide-item-descr'>
                                    
                                    <h1>{slide.title}</h1>
                                    {slide.price ? (<h2>{slide.price} рублей</h2>) : null}
                                    <NavLink to={slide.link}><button>Подробнее</button></NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='right-slide-button' onClick={handleNextSlide}><img src={arrow} alt="Right Arrow" /></div>
                </div>
                
            </div>
        </div>
    );
}
