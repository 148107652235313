import React, { useState } from 'react';
import exitBtn from '../content/exit-cross-btn.svg';
import axios from 'axios';
import { ServerAddres } from '../App';

export default function CallOrder() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        description: '',
        email: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        // Сбрасываем ошибку при изменении значения поля
        setErrors({
            ...errors,
            [e.target.name]: ''
        });
        // Сбрасываем класс ошибки при изменении значения поля
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
    
        if (!formData.name) {
            formIsValid = false;
            errors.name = 'Пожалуйста, введите ваше имя';
        }
    
        if (!formData.phone) {
            formIsValid = false;
            errors.phone = 'Пожалуйста, введите ваш телефон';
        }
        if (!formData.email) {
            formIsValid = false;
            errors.email = 'Пожалуйста, введите ваш email';
        }
    
        setErrors(errors);
    
        return formIsValid;
    };

    const closeWindow = () => {
        document.querySelector('.call-order-menu').style.display = 'none';
        document.querySelector('.call-order-menu-bg').style.display = 'none';
    };

    const sendForm = async () => {
        if (!validateForm()) {
            return;
        }
    
        const callText = document.getElementById('call-order-info-text');
    
        try {
            const baseUrl = `${ServerAddres}`; // Получаем адрес сервера из переменной окружения, либо используем адрес по умолчанию
            const response = await axios.post(`${baseUrl}/send-email`, formData);
            console.log(response.data); // Можете обработать ответ сервера, если это необходимо
            callText.innerHTML = 'Запрос отправлен, мы скоро с вами свяжемся.';
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
            callText.innerHTML = 'Ошибка отправки запроса! Попробуйте позже или свяжитесь с нами по номеру +7(915)600-35-11';
        }
    };
    

    return (
        <>
            <div className='call-order-menu-bg'></div>
            <div className={`call-order-menu`}>
                <div className="call-order-menu-info">
                    <h1>Оставьте заявку</h1>
                    <p id='call-order-info-text'>Оставьте заявку, и наши менеджеры скоро свяжутся с Вами!</p>
                </div>
                <form>
                    <input
                        type="text"
                        name='name'
                        placeholder={!errors.name ? 'Ваше имя *' : errors.name}
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name='phone'
                        placeholder={!errors.phone ? 'Телефон *' : errors.phone}
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name='email'
                        placeholder={!errors.email ? 'Email *' : errors.email}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <textarea
                        placeholder='Комментарий'
                        name='description'
                        value={formData.description}
                        onChange={handleChange}
                    />
                    <input onClick={sendForm} type="button" value="Отправить запрос" />
                </form>
                <div className='exit-btn' onClick={closeWindow}>
                    <img width='24px' src={exitBtn} alt="" />
                </div>
            </div>
        </>
    );
} 
