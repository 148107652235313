import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cookies from 'react-cookies'

import './AdminPanel.css'
import './AdminAdaptive.css'
import './Login.css'
import { ServerAddres } from '../../App';

const parameterExplanations = {
  scrubbers: {
    batteryType: 'Батарея',
    dirtyWaterVolume: 'Объем бака для грязной воды',
    workWidthBrushes: 'Рабочая ширина щеток, мм',
    motorPowerBrushes: 'Мощность мотора щетки, Вт',
    pressureBrush: 'Давление прижима щетки, кг',
    driveMotorPower: 'Мощность мотора привода',
    machineVoltage: 'Напряжение машины, В',
    oneChargeTime: 'Время работы на одном заряде, ч',
    dimensions: 'Габаритные размеры, ДхШхВ',
    cleanWaterVolume: 'Объем бака для чистой воды',
    efficiency: 'Теоретическая производительность, м²/ч',
    brushDiameterQuantity: 'Диаметр щётки х кол-во, мм',
    brushRotationSpeed: 'Скорость вращения щетки, об/мин',
    widthDrainageBeam: 'Ширина водосборной балки, мм',
    vehicleSpeed: 'Скорость машины, км/ч',
    batteryAndQuantity: 'Батарея х кол-во',
    noiseLevel: 'Уровень шума, Дб',
    weightWithBattery: 'Вес с батареями, кг',
  },
  detergents: {
    number: 'Номер',
    workTemp: 'Рабочая температура, °C',
    substanceType: 'Тип химии',
    workTime: 'Время мойки, мин',
    ph: 'Кислотный показатель pH',
    chemType:"Тип химии"
  },
  'food-equipment': {
    article: 'Артикул',
    price: 'Цена',
    var: 'Название',
    colors: 'Цвета',
  },
  'hp-washers':{
      type:'Тип',
      powerElectric:'Питание(Электрические), (ф/В/Гц)',
      powerGas:'Питание (Бензиновые)',
      efficiency:'Производительность, (л/мин)',
      pressure:'Рабочее давление, (бар)',
      powerConsumption:'Потребляемая мощность, (кВт)',
      maxTemperature:'Макс. температура воды на входе, (°C)',
      dimensions:'Размеры ДхШхВ, (мм)',
      power:'Мощность, кВт',
        rpm:'Оборотов двигателя, об/мин',
        pompConstruction:"Конструкция помпы",
        pompMaterial:"Материал помпы",
        motorPomp:"Материал мотора"
  },
  'ge-lawn-mower': {
    "lawn-mower-type": "Тип",
        "engine-brand": "Марка двигателя",
        "engine-model": "Модель двигателя",
        "engine-type": "Тип двигателя",
        "engine-power": "Мощность двигателя, л.с.",
        "cylinder-volume": "Объем двигателя, см³",
        "cylinder-count": "Количество цилиндров",
        "cooling-system": "Охлаждение",
        "fuel-volume": "Объем топливного бака, л",
        "cutting-width": "Ширина кошения, см",
        "cutting-height": "Высота стрижки",
        "blade-count": "Количество ножей",
        "max-torque":"Максимальный крутящий момент",
        "max-slope": "Максимально допустимый уровень склона, %",
        "turning-radius": "Радиус поворота, см",
        "differential-lock": "Блокировка дифференциала",
        "cruise-control": "Круиз-контроль",
        "drive": "Привод",
        "transmission": "Тип трансмиссии",
        "speed": "Скорость, км/ч",
        "grass-collector": "Травосборник",
        "rear-discharge": "Задний выброс",
        "side-discharge": "Боковой выброс",
        "mulching": "Мульчирование",
        "cab": "Кабина",
        "wheels": "Колеса",
        "kit": "Комплект",
        "application": "Применение",
        "dimensions": "Габариты",
        "weight": "Вес, кг",
        "front-wheels": "Передние колеса",
        "rear-wheels": "Задние колеса",

        "processing-area":"Площадь обработки, м²",
        "height-adjustment":"Центральная регулировка высоты стрижки",
        "collector-capacity":"Емкость травосборника",
        "box-dimension":"Габариты коробки",
        "body-material": "Материал корпуса",
        "handle": "Рукоятка",
        "collector-lift-height": "Высота подъема травосборника",
        "deck-drive": "Привод деки",
        "starter":"Стартер",
        "max-pressure": "Максимальное давление",
        "blade-type": "Тип ножа",
        "connect-module":"Connect модуль (GPS, GSM)	",
        "battery-type": "Тип аккумулятора",
        "autonomous-runtime": "Время автономной работы",
        "max-work-zones": "Максимальное количество рабочих зон",
        "charging-method": "Способ подзарядки",
        "grass-detection-sensor": "Датчик распознования травы",
        "lift-tilt-sensor": "Датчик подъема/опрокидывания",
        "bluetooth-receiver": "Bluetooth-ресивер",
        "eco-mode": "Экономичный режим",
        "emergency-start-stop-button": "Экстренная кнопка пуска/останова",
      "protection-level": "Уровень защиты",
      "perimeter-wire": "Провод периметра",
      "noise-level": "Уровень шума",
  },
  vacuums:{
    "power":"Мощность, Вт",
    "tank-volume":"Объем бака, Л",
    "voltage":"Напряжение, В",
    "underpressure":"Разрежение, mBar",
    "air-flow":"Расход воздуха, л/с",
    "cable-lenght":"Кабель, м",
    "dimensions":"Размер коробки, мм",
    "weight":"Вес, кг"
}
};

function EditText() {
  const [editType, setEditType] = useState('Выберите');
  const [editPos, setEditPos] = useState('Выберите');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newContent, setNewContent] = useState('');

  useEffect(() => {
    axios.get(`${ServerAddres}/data`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  useEffect(() => {
    filterData();
  }, [editType, editPos, data]);

  const handleEditTypeChange = (event) => {
    setEditType(event.target.value);
  };

  const handleEditPosChange = (event) => {
    setEditPos(event.target.value);
  };

  const filterData = () => {
    const filtered = data.filter(item => 
      item.id.startsWith(editType) && item.screen === editPos
    );
    setFilteredData(filtered);
  };

  const handleFilteredDataChange = (event) => {
    const selected = filteredData.find(item => item.id === event.target.value);
    setSelectedItem(selected);
    setNewContent(selected ? selected.content : '');
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleTextChange = (event) => {
    setNewContent(event.target.value);
  };

  const handleUpdateText = () => {
    if (selectedItem) {
      axios.post(`${ServerAddres}/update-text`, {
        id: selectedItem.id,
        content: newContent
      })
      .then(response => {
        console.log('Text updated successfully:', response);
        // Обновляем данные в состоянии
        const updatedData = data.map(item =>
          item.id === selectedItem.id ? { ...item, content: newContent } : item
        );
        setData(updatedData);
      })
      .catch(error => {
        console.error('Error updating text:', error);
      });
    }
  };

  const handleUploadImage = async () => {
    if (selectedItem && selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
      formData.append('id', selectedItem.id);

      try {
        const response = await axios.post(`${ServerAddres}/update-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Image uploaded successfully:', response);
        // Обновляем данные в состоянии
        const updatedData = data.map(item =>
          item.id === selectedItem.id ? { ...item, content: response.data.imagePath } : item
        );
        setData(updatedData);
        setSelectedImage(null);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <div className="admin-menu">
      <div>
        <label>Тип</label>
        <select value={editType} onChange={handleEditTypeChange}>
          <option value="Выберите">Выберите</option>
          <option value="text">Текст</option>
          <option value="img">Изображение</option>
        </select>
      </div>
      <div>
        <label>Расположение</label>
        <select value={editPos} onChange={handleEditPosChange}>
          <option value="Выберите">Выберите</option>
          <option value="Главный_Начальный">Главный_Начальный</option>
          <option value="Главный_О-нас">Главный_О-Нас</option>
          <option value="Главный_Преимущества">Главный_Преимущества</option>
          <option value="Главный_Каталог">Главный_Каталог</option>
          <option value="Главный_Нижний">Главный_Нижний</option>
        </select>
      </div>
      {editType === 'img' && (
        <div>
          <input type="file" onChange={handleImageChange} />
          {selectedImage && (
            <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
          )}
          <button onClick={handleUploadImage}>Загрузить изображение</button>
        </div>
      )}
      <div>
        <label>Объект</label>
        <select onChange={handleFilteredDataChange}>
          <option value="">Выберите</option>
          {filteredData.map(item => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}
        </select>
      </div>
      <div>
        {selectedItem && (
          <div>
            <h3>{selectedItem.name}</h3>
            {editType === 'text' ? (
              <div>
                <textarea value={newContent} onChange={handleTextChange} />
                <button onClick={handleUpdateText}>Обновить текст</button>
              </div>
            ) : (
              selectedItem.content.startsWith(`${ServerAddres}/images/`) ? (
                <img src={selectedItem.content} alt={selectedItem.name} className="admin-menu-img" />
              ) : (
                <p>{selectedItem.content}</p>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function EditProduct() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productType, setProductType] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productIndividual, setProductIndividual] = useState({});
  const [productVariations, setProductVariations] = useState([]);
  const [productImages, setProductImages] = useState([]);


  useEffect(() => {
    axios.get(`${ServerAddres}/products`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the products!', error);
      });
  }, []);

  useEffect(() => {
    filterProducts();
  }, [productType, products]);

  const filterProducts = () => {
    const filtered = products.filter(product => product.type === productType);
    setFilteredProducts(filtered);
  };

  const handleProductTypeChange = (event) => {
    const type = event.target.value;
    setProductType(type);
    if (type === '') {
      resetProductForm();
    }
  };
  

  const handleProductSelect = (event) => {
    const productId = parseInt(event.target.value, 10);
    const product = filteredProducts.find(p => p.id === productId);
    if (product) {
      setSelectedProduct(product);
      setProductName(product.name || product.model);
      setProductPrice(product.price);
      setProductDescription(product.description);
      setProductIndividual(product.individual || {});
      setProductVariations(product.variations || []);

    } else {
      resetProductForm();
    }
  };

  const handleUpdateProduct = () => {
    if (selectedProduct) {
        const formData = new FormData();
        formData.append('id', selectedProduct.id);
        formData.append('type', selectedProduct.type || productType);
        formData.append('model', selectedProduct.model);
        formData.append('brand', selectedProduct.brand);
        formData.append('price', productPrice);
        formData.append('description', productDescription);
        formData.append('individual', JSON.stringify(productIndividual));
        formData.append('variations', JSON.stringify(productVariations));
        if (productImages.length > 0) {
            productImages.forEach((image, index) => {
                formData.append('images', image); // Измените на 'images'
            });
        }
        axios.post(`${ServerAddres}/update-product`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            console.log(response.data);
            const updatedProducts = products.map(product =>
                product.id === selectedProduct.id ?
                    { ...product, name: productName, price: productPrice, description: productDescription, individual: productIndividual, variations: productVariations, images: productImages.map(img => URL.createObjectURL(img)), type: selectedProduct.type, model: selectedProduct.model, brand: selectedProduct.brand } :
                    product
            );
            setProducts(updatedProducts);
            resetProductForm();
        })
        .catch(error => {
            console.error('Error updating product:', error);
        });
    }
};

  
  //Функция  обработки изображений
  const handleImagesChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (productImages.length + selectedFiles.length <= 5) {
      setProductImages(prevImages => [...prevImages, ...selectedFiles]);
    } else {
      alert('Вы можете загрузить максимум 5 изображений.');
    }
  };
  
  const moveImage = (index, direction) => {
    const newImages = [...productImages];
    if (direction === 'left' && index > 0) {
      [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
      setProductImages(newImages);
    }
    if (direction === 'right' && index < newImages.length - 1) {
      [newImages[index + 1], newImages[index]] = [newImages[index], newImages[index + 1]];
      setProductImages(newImages);
    }
  };
  
  const removeImage = (index) => {
    setProductImages(prevImages => prevImages.filter((_, i) => i !== index));
  };
  
  


  const handleDeleteProduct = () => {
    if (selectedProduct) {
      const productId = selectedProduct.id;
      axios.post(`${ServerAddres}/delete-product/${productId}`)
        .then(response => {
          console.log(response.data);
          const updatedProducts = products.filter(product => product.id !== productId);
          setProducts(updatedProducts);
          resetProductForm();
        })
        .catch(error => {
          console.error('Error deleting product:', error);
        });
    }
  };

  const handleColorChange = (index, color) => {
    const newVariations = [...productVariations];
    const variation = newVariations[index];
    const newColors = variation.colors.includes(color) ?
      variation.colors.filter(c => c !== color) :
      [...variation.colors, color];
    newVariations[index] = { ...variation, colors: newColors };
    setProductVariations(newVariations);
  };

  const resetProductForm = () => {
    setSelectedProduct(null);
    setProductName('');
    setProductPrice('');
    setProductDescription('');
    setProductIndividual({});
    setProductVariations([]);
    setProductImages([]);
  };
  

  return (
    <div className="admin-menu">
      <h3>Редактировать товар</h3>
      <div>
        <label>Тип продукта</label>
        <select value={productType} onChange={handleProductTypeChange}>
          <option value="">Выберите тип...</option>
          <option value="scrubbers">Поломоечные машины</option>
          <option value="detergents">Моющие средства</option>
          <option value="food-equipment">Профессиональный пищевой инвентарь</option>
          <option value="ge-lawn-mower">Газонокосилки</option>
          <option value="hp-washers">Аппараты высокого давления</option>
          <option value="vacuums">Пылесосы</option>
        </select>
      </div>
      {productType && (
        <div>
          <label>Выберите товар</label>
          <select onChange={handleProductSelect}>
            <option value="">Выберите...</option>
            {filteredProducts.map(product => (
              <option key={product.id} value={product.id}>{product.id} - {product.type === 'detergents' ? product.individual.number : (product.name || product.model)}</option>
            ))}
          </select>
        </div>
      )}
      {selectedProduct && (
        <div className="product-details">
          <div>
            
              {/*Если одно изображение */}
              {/*
                  {selectedOption === 'одно' ? (
                    <>
                    <label>Изображение</label>
                  <input type="file" onChange={handleImageChange} />
                  {selectedProduct.image && !productImage && (
                    <img src={selectedProduct.image} alt="Current" className="admin-menu-img" />
                  )}
                  {productImage && (
                    <img src={URL.createObjectURL(productImage)} alt="Selected" className="admin-menu-img" />
                  )}
                    </>
                  ) : null} */}
              {/*Если несколько изображений */}
              
                <>
                <label>Изображения (до 5)</label>
                <input 
                  type="file" 
                  onChange={handleImagesChange} 
                  multiple 
                  accept="image/*"
                  disabled={productImages.length >= 5}
                />
                <div className="images-preview">
                  {productImages.map((image, index) => (
                    <div className='image-preview' key={index}>
                      <div className="imArrs">
                        <div className='ImArr' onClick={() => moveImage(index, 'left')}>&lt;</div>
                        <div className='ImArr' onClick={() => moveImage(index, 'right')}>&gt;</div>
                        <div className='ImArr' onClick={() => removeImage(index)}>×</div>
                      </div>
                      <div className='image-container'>
                        <img 
                          src={URL.createObjectURL(image)} 
                          alt={`Selected ${index}`} 
                          className="admin-menu-img" 
                        />
                      </div>
                    </div>
                  ))}
                </div>

              </>              
              
          </div>
          <div>
            <label>Название</label>
            <input type="text" value={productName} onChange={e => setProductName(e.target.value)} />
          </div>
          <div>
            <label>Цена</label>
            <input type="text" value={productPrice} onChange={e => setProductPrice(e.target.value)} />
          </div>
          <div>
            <label>Описание</label>
            <textarea value={productDescription} onChange={e => setProductDescription(e.target.value)} />
          </div>
          {Object.keys(productIndividual).length > 0 && (
            <div>
              <h4>Индивидуальные параметры</h4>
              {productType && Object.keys(parameterExplanations[productType]).map(key => (
                <div key={key}>
                  <label>{parameterExplanations[productType][key]}</label>
                  {key === 'type' ? (
                    <select
                      value={productIndividual[key]}
                      onChange={e => setProductIndividual({ ...productIndividual, [key]: e.target.value })}
                    >
                      <option value="apparatus">АВД</option>
                      <option value="accessories">Комплектующие</option>
                    </select>
                  ) : (
                    <input type="text" value={productIndividual[key] || ''} onChange={e => setProductIndividual({ ...productIndividual, [key]: e.target.value })} />
                  )}
                </div>
              ))}
            </div>
          )}
          {productVariations.length > 0 && (
            <div>
              <h4>Вариации</h4>
              {productVariations.map((variation, index) => (
                <div key={index} style={{ marginBottom: '10px', padding: '10px', borderBottom: '1px solid #ccc' }}>
                  <label>Вариация {index + 1}</label>
                  {productType === 'hp-washers' ? (
                    <>
                      <div>
                        <label>Type</label>
                        <select
                          value={variation.type}
                          onChange={e => {
                            const newVariations = [...productVariations];
                            newVariations[index] = { ...variation, type: e.target.value };
                            setProductVariations(newVariations);
                          }}
                        >
                          <option value="apparatus">АВД</option>
                          <option value="accessories">Комплектующие</option>
                        </select>
                      </div>
                      <div>
                        <label>Var</label>
                        <input
                          type="text"
                          value={variation.var}
                          onChange={e => {
                            const newVariations = [...productVariations];
                            newVariations[index] = { ...variation, var: e.target.value };
                            setProductVariations(newVariations);
                          }}
                        />
                      </div>
                      <div>
                        <label>Price</label>
                        <input
                          type="text"
                          value={variation.price}
                          onChange={e => {
                            const newVariations = [...productVariations];
                            newVariations[index] = { ...variation, price: e.target.value };
                            setProductVariations(newVariations);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {Object.keys(variation).map(subKey => (
                        (subKey !== 'colors') && (
                          <div key={subKey}>
                            <label>{subKey}</label>
                            <input
                              type="text"
                              value={variation[subKey]}
                              onChange={e => {
                                const newVariations = [...productVariations];
                                newVariations[index] = { ...variation, [subKey]: e.target.value };
                                setProductVariations(newVariations);
                              }}
                            />
                          </div>
                        )
                      ))}
                      <div>
                        <label>Цвета</label>
                        {['white', 'blue', 'red', 'yellow', 'mediumseagreen', 'black', 'orange', 'purple', 'gray', 'saddlebrown', 'deeppink'].map((color, colorIndex) => (
                          <label key={colorIndex} style={{ marginRight: '10px', display: 'inline-block' }}>
                            <input
                              type="checkbox"
                              checked={variation.colors.includes(color)}
                              onChange={() => handleColorChange(index, color)}
                            />
                            <span
                              style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: color,
                                border: '1px solid black',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                cursor: 'pointer'
                              }}
                            ></span>
                          </label>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button onClick={handleUpdateProduct}>Обновить товар</button>
            <button onClick={handleDeleteProduct} style={{ backgroundColor: 'red', color: 'white',marginRight:'-15px'}}>Удалить товар</button>
          </div>
        </div>
      )}
    </div>
  );
}
const AddProduct = () => {
  const [productType, setProductType] = useState('');
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productBrand, setProductBrand] = useState('');
  const [productIndividual, setProductIndividual] = useState({});
  const [productVariations, setProductVariations] = useState([]);

  const [productImages, setProductImages] = useState([]);
  const [detergentNumber, setDetergentNumber] = useState('');

  const handleProductTypeChange = (event) => {
    const type = event.target.value;
    setProductType(type);
    setProductBrand('');
    setProductIndividual({});
    setProductVariations([]);
  };

  const handleAddVariation = () => {
    setProductVariations([...productVariations, { var: '', price: '' }]);
  };

  const handleVariationChange = (index, field, value) => {
    const newVariations = [...productVariations];
    newVariations[index][field] = value;
    setProductVariations(newVariations);
  };

  const handleAddProduct = () => {
    const formData = new FormData();
    formData.append('type', productType);
    formData.append('name', productName);
    formData.append('brand', productBrand);
    formData.append('price', productPrice);
    formData.append('description', productDescription);
    formData.append('variations', JSON.stringify(productVariations));
    formData.append('individual', JSON.stringify(productIndividual));
    if (productType === 'detergents') {
      formData.append('number', detergentNumber);
    }
    if (productImages.length > 0) {
        productImages.forEach((image) => {
            formData.append('images', image); // Измените на 'images' для отправки всех изображений
        });
    }

    axios.post(`${ServerAddres}/add-product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log(response.data);
      resetProductForm();
    })
    .catch(error => {
      console.error('Error adding product:', error);
    });
};


  

  const handleImagesChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (productImages.length + selectedFiles.length <= 5) {
      setProductImages(prevImages => [...prevImages, ...selectedFiles]);
    } else {
      alert('Вы можете загрузить максимум 5 изображений.');
    }
};


const resetProductForm = () => {
  setProductType('');
  setProductName('');
  setProductPrice('');
  setProductDescription('');
  setProductBrand('');
  setProductIndividual({});
  setProductVariations([]);
  setProductImages([]);  // Очистка массива изображений
  setDetergentNumber('');
};

const removeImage = (index) => {
  setProductImages(prevImages => prevImages.filter((_, i) => i !== index));
};

const moveImage = (index, direction) => {
  const newImages = [...productImages];
  if (direction === 'left' && index > 0) {
    [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
    setProductImages(newImages);
  }
  if (direction === 'right' && index < newImages.length - 1) {
    [newImages[index + 1], newImages[index]] = [newImages[index], newImages[index + 1]];
    setProductImages(newImages);
  }
};



  return (
    <div className="admin-menu">
      <h3>Добавить новый товар</h3>
      <div>
        <label>Тип продукта</label>
        <select value={productType} onChange={handleProductTypeChange}>
          <option value="">Выберите тип...</option>
          <option value="scrubbers">Поломоечные машины</option>
          <option value="detergents">Моющие средства</option>
          <option value="food-equipment">Профессиональный пищевой инвентарь</option>
          <option value="ge-lawn-mower">Газонокосилки</option>
          <option value="hp-washers">Аппараты высокого давления</option>
        </select>
      </div>
      {productType && (
        <div>
          <div>
            <label>Название</label>
            <input type="text" value={productName} onChange={e => setProductName(e.target.value)} />
          </div>
          <div>
            <label>Бренд</label>
            <input type="text" value={productBrand} onChange={e => setProductBrand(e.target.value)} />
          </div>
          {productType !== 'food-equipment' && (
            <div>
              <div>
                <label>Цена</label>
                <input type="text" value={productPrice} onChange={e => setProductPrice(e.target.value)} />
              </div>
              <div>
                <label>Описание</label>
                <textarea value={productDescription} onChange={e => setProductDescription(e.target.value)} />
              </div>
            </div>
          )}
          <>
                <label>Изображения (до 5)</label>
                <input 
                  type="file" 
                  onChange={handleImagesChange} 
                  multiple 
                  accept="image/*"
                  disabled={productImages.length >= 5}
                />
                <div className="images-preview">
                  {productImages.map((image, index) => (
                    <div className='image-preview' key={index}>
                      <div className="imArrs">
                        <div className='ImArr' onClick={() => moveImage(index, 'left')}>&lt;</div>
                        <div className='ImArr' onClick={() => moveImage(index, 'right')}>&gt;</div>
                        <div className='ImArr' onClick={() => removeImage(index)}>×</div>
                      </div>
                      <div className='image-container'>
                        <img 
                          src={URL.createObjectURL(image)} 
                          alt={`Selected ${index}`} 
                          className="admin-menu-img" 
                        />
                      </div>
                    </div>
                  ))}
                </div>

              </>   


          {productType !== 'food-equipment' && (
            <div>
              <h4>Индивидуальные параметры</h4>
              {productType && Object.keys(parameterExplanations[productType]).map(key => (
                <div key={key}>
                  <label>{parameterExplanations[productType][key]}</label>
                  {key === 'chemType' ? (
                    <select
                      value={productIndividual[key] || ''}
                      onChange={e => setProductIndividual({ ...productIndividual, [key]: e.target.value })}
                    >
                      <option value="food-industry">Пищевая промышленность</option>
                      <option value="cleaning">Клининг</option>
                      <option value="industrial">Индустриальная химия</option>
                    </select>
                  ) : key === 'type' && productType === 'hp-washers' ? (
                    <select
                      value={productIndividual[key] || ''}
                      onChange={e => setProductIndividual({ ...productIndividual, [key]: e.target.value })}
                    >
                      <option value="apparatus">АВД</option>
                      <option value="accessories">Комплектующие</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      value={productIndividual[key] || ''}
                      onChange={e => setProductIndividual({ ...productIndividual, [key]: e.target.value })}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {productType === 'food-equipment' && (
            <div>
              <h4>Вариации</h4>
              {productVariations.map((variation, index) => (
                <div key={index} style={{ marginBottom: '10px', padding: '10px', borderBottom: '1px solid #ccc' }}>
                  <div>
                    <label>Артикул</label>
                    <input
                      type="text"
                      value={variation.article}
                      onChange={e => handleVariationChange(index, 'article', e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Цена</label>
                    <input
                      type="text"
                      value={variation.price}
                      onChange={e => handleVariationChange(index, 'price', e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Название</label>
                    <input
                      type="text"
                      value={variation.var}
                      onChange={e => handleVariationChange(index, 'var', e.target.value)}
                    />
                  </div>
                </div>
              ))}
              <button onClick={handleAddVariation}>Добавить вариацию</button>
            </div>
          )}
          {productType === 'hp-washers' && (
            <div>
              <h4>Вариации</h4>
              {productVariations.map((variation, index) => (
                <div key={index} style={{ marginBottom: '10px', padding: '10px', borderBottom: '1px solid #ccc' }}>
                  <div>
                    <label>Название</label>
                    <input
                      type="text"
                      value={variation.var}
                      onChange={e => handleVariationChange(index, 'var', e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Цена</label>
                    <input
                      type="text"
                      value={variation.price}
                      onChange={e => handleVariationChange(index, 'price', e.target.value)}
                    />
                  </div>
                </div>
              ))}
              <button onClick={handleAddVariation}>Добавить вариацию</button>
            </div>
          )}
          <button onClick={handleAddProduct}>Добавить товар</button>
        </div>
      )}
    </div>
  );
};

function AdminPanel() {
  const [adminType, setAdminType] = useState('text_edit');
  const [activeButton, setActiveButton] = useState('text_edit');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const token = cookies.load('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleAdminTypeChange = (type) => {
    setAdminType(type);
    setActiveButton(type);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
        const response = await axios.post(`${ServerAddres}/login`, { username, password }, {
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 200) {
            const { token } = response.data;
            cookies.save('token', token, { path: '/', maxAge: 43200 }); // 12 часов = 43200 секунд
            setIsLoggedIn(true);
            setError('');
        } else {
            setError(response.data.message || 'Ошибка входа');
        }
    } catch (error) {
      if (error.response) {
          // Сервер ответил с кодом состояния, который не находится в диапазоне 2xx
          console.error('Response error:', error.response.data);
          setError(error.response.data.message || 'Ошибка на стороне сервера');
      } else if (error.request) {
          // Запрос был сделан, но ответ не получен
          console.error('Request error:', error.request);
          setError('Ошибка соединения с сервером');
      } else {
          // Произошло что-то при настройке запроса, вызвавшее ошибку
          console.error('Error:', error.message);
          setError('Произошла ошибка');
      }
  }
};



  const handleLogout = () => {
    cookies.remove('token', { path: '/' });
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <main className='login-container'>
  <div className='login-box'>
    <form onSubmit={handleLogin}>
      <div className='user-box'>
        <input
          type='text'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          autoComplete='username' // Указываем для поля логина
        />
        <label>Имя пользователя</label>
      </div>
      <div className='user-box'>
        <input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete='current-password' // Указываем для поля пароля
        />
        <label>Пароль</label>
      </div>
      {error && <p className='error-message'>{error}</p>}
      <button className='submit' type='submit'>
        Войти
      </button>
    </form>
  </div>
</main>
    );
  }

  return (
    <main className='admin-panel'>
        <h2 className='admin-title'>Admin Panel</h2>
        <button className='logout-button' onClick={handleLogout}>Выйти</button>
        <div className="switcher">
            <button className={`admFunc ${activeButton === 'text_edit' ? '__active' : ''}`} onClick={() => handleAdminTypeChange('text_edit')}>Редактировать текст</button>
            <button className={`admFunc ${activeButton === 'product_edit' ? '__active' : ''}`} onClick={() => handleAdminTypeChange('product_edit')}>Редактировать товары</button>
            <button className={`admFunc ${activeButton === 'product_add' ? '__active' : ''}`} onClick={() => handleAdminTypeChange('product_add')}>Добавить товары</button>
        </div>
        {adminType === 'text_edit' && <EditText />}
        {adminType === 'product_edit' && <EditProduct />}
        {adminType === 'product_add' && <AddProduct />}
    </main>
);
}

export default AdminPanel;
