import React, { useState, useRef, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import location from '../content/location.svg';
import phone from '../content/phone-number.svg';
import logo from '../content/apriori-logo-small.svg';
import burgerIcon from '../content/burger-icon.svg';
import CatalogSidebar from './CataloguePage/CatalogSidebar';

export default function Header() {
    const [isCatalogueHovered, setCatalogueHovered] = useState(false);
    const [isCatalogueChemHovered, setCatalogueChemHovered] = useState(false);
    const [isWasherHovered, setWasherHovered] = useState(false);
    const [isGEHovered, setGEHovered] = useState(false);
    const catalogueRef = useRef(null);
    const dropListRef = useRef(null);
    const chemRef = useRef(null);
    const chemListRef = useRef(null);
    const washerRef = useRef(null);
    const washerListRef = useRef(null);
    const GEListRef = useRef(null);
    const GERef = useRef(null);
    const [catalogueListPosition, setCatalogueListPosition] = useState({ left: 0 });
    const [chemListPosition, setChemListPosition] = useState({ left: 0 });
    const [washerListPosition, setWasherListPosition] = useState({ left: 0 });
    const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
    const burgerMenuRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (burgerMenuRef.current && !burgerMenuRef.current.contains(event.target)) {
            setBurgerMenuOpen(false);
        }
    };

    const toggleBurgerMenu = () => {
        setBurgerMenuOpen(!isBurgerMenuOpen);
    };

    const closeBurgerMenu = () => {
        setBurgerMenuOpen(false);
    };

    const handleCatalogueHover = () => {
        setCatalogueHovered(true);
    };

    const handleCatalogueChemHover = () => {
        setCatalogueChemHovered(true);
    };

    const handleWasherHover = () => {
        setWasherHovered(true);
    };

    const handleCatalogueLeave = () => {
        setCatalogueHovered(false);
    };

    const handleCatalogueChemLeave = () => {
        setCatalogueChemHovered(false);
    };

    const handleWasherLeave = () => {
        setWasherHovered(false);
    };
    const handleGELeave = () => {
        setGEHovered(false);
    };
    const handleGEHover = () => {
        setGEHovered(true);
    };

    useEffect(() => {
        const updatePosition = () => {
            const catalogueButton = catalogueRef.current;
            if (catalogueButton) {
                const buttonRect = catalogueButton.getBoundingClientRect();
                const catalogueList = dropListRef.current;
                if (catalogueList) {
                    setCatalogueListPosition({ left: buttonRect.left - 55 });
                }
            }
        };
        updatePosition();
    }, [isCatalogueHovered]);

    useEffect(() => {
        const updatePosition = () => {
            const chemButton = chemRef.current;
            if (chemButton) {
                const chemButtonRect = chemButton.getBoundingClientRect();
                const chemList = chemListRef.current;
                if (chemList) {
                    setChemListPosition({ left: chemButtonRect.right });
                }
            }
        };
        updatePosition();
    }, [isCatalogueChemHovered]);

    useEffect(() => {
        const updatePosition = () => {
            const washerButton = washerRef.current;
            if (washerButton) {
                const washerButtonRect = washerButton.getBoundingClientRect();
                const washerList = washerListRef.current;
                if (washerList) {
                    setWasherListPosition({ left: washerButtonRect.right });
                }
            }
        };
        updatePosition();
    }, [isWasherHovered]);

    const handleWasherListHover = () => {
        setWasherHovered(true);
    };

    const handleWasherListLeave = () => {
        setWasherHovered(false);
    };

    const handleGEListHover = () => {
        setGEHovered(true);
    };

    const handleGEListLeave = () => {
        setGEHovered(false);
    };

    const showSendWindow = () => {
        const call_text = document.getElementById('call-order-info-text')
        document.querySelector('.call-order-menu').style.display = 'flex';
        document.querySelector('.call-order-menu-bg').style.display = 'flex';
        call_text.innerHTML = 'Оставьте заявку, и наши менеджеры скоро свяжутся с Вами!';
    };
    const scrollToFooter = () => {
        const footerElement = document.getElementById('footer');
        if (footerElement) {
            footerElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header>
            <div className="drop-list-catalogue" onMouseEnter={handleCatalogueHover} onMouseLeave={handleCatalogueLeave} ref={dropListRef} style={{ display: (isCatalogueHovered || isCatalogueChemHovered || isWasherHovered || isGEHovered) ? 'grid' : 'none', left: `${catalogueListPosition.left}px` }}>
                <NavLink to='/catalog/scrubbers/'>Поломоечные машины</NavLink>
                <NavLink to='/catalog/high-pressure-washers/' className='arrow-list' ref={washerRef} onMouseEnter={handleWasherHover} onMouseLeave={handleWasherLeave}>Мойки высокого давления</NavLink>
                <NavLink to='/catalog/vacuum-cleaners/'>Пылесосы</NavLink>
                <NavLink ref={chemRef} className='arrow-list' to='/catalog/detergents/' onMouseEnter={handleCatalogueChemHover} onMouseLeave={handleCatalogueChemLeave}>Моющие средства</NavLink>
                <NavLink to='/catalog/food-equipment/'>Профессиональный пищевой инвентарь</NavLink>
                <NavLink className='arrow-list' ref={GERef} onMouseEnter={handleGEHover} onMouseLeave={handleGELeave} to='/catalog/garden-equipment/'>Садовая техника</NavLink>
                <NavLink to='/catalog/dezkovriki/'>Дезковрики</NavLink>
            </div>
            <div className="drop-list-chem-catalogue" onMouseEnter={handleCatalogueChemHover} onMouseLeave={handleCatalogueChemLeave} ref={chemListRef} style={{ display: isCatalogueChemHovered ? 'grid' : 'none', left: `${chemListPosition.left}px` }}>
                <NavLink to='/catalog/food-industry/'>Пищевая промышленность</NavLink>
                <NavLink to='/catalog/cleaning/'>Клининг</NavLink>
                <NavLink to='/catalog/industial/'>Индустриальная химия</NavLink>
            </div>
            <div className="washer-list" onMouseEnter={handleWasherListHover} onMouseLeave={handleWasherListLeave} ref={washerListRef} style={{ display: isWasherHovered ? 'grid' : 'none', left: `${washerListPosition.left}px` }}>
                <NavLink to='/catalog/hp-apparatus/'>Аппараты высокого давления</NavLink>
                <NavLink to='/catalog/hp-accessories/'>Комплектующие для АВД</NavLink>
            </div>
            <div className="garden-equipment-list" onMouseEnter={handleGEListHover} onMouseLeave={handleGEListLeave} ref={GEListRef} style={{ display: isGEHovered ? 'grid' : 'none', left: `${washerListPosition.left}px` }}>
                <NavLink to='/catalog/ge-lawn-mower/'>Газонокосилки</NavLink>
                <NavLink to='/catalog/ge-snow-blower/'>Снегоуборщики</NavLink>
            </div>
            <div className="upside-header">
                <div className="location">
                    <img src={location} alt="" />
                    <NavLink to="https://yandex.ru/maps/11/ryazan/house/bystretskaya_ulitsa_10/Z0AYcQ5iS0YFQFtufXpzdH5gbQ==/?ll=39.792325%2C54.625302&z=20.2">
                        <p>г. Рязань, ул. Быстрецкая д. 10</p>
                    </NavLink>
                </div>
                <div className="phone-number">
                    <img src={phone} alt="" />
                    <p>+7 (915) 600-35-11</p>
                </div>
                <div className={`burger-menu ${isBurgerMenuOpen ? 'open' : ''}`}>
                    <div className="burger-menu-content" ref={burgerMenuRef}>
                        <button className="close-btn" onClick={closeBurgerMenu}>X</button> {/* Кнопка закрытия */}
                        <NavLink to="/"><h4 onClick={closeBurgerMenu}>Главная</h4></NavLink>
                        <h4 onClick={()=>{
                            closeBurgerMenu()
                            scrollToFooter()
                        }}>Контакты</h4>
                        <div className="catalog-menu" onClick={closeBurgerMenu}>
                            {isBurgerMenuOpen && <CatalogSidebar  />}
                        </div>
                    </div>
                </div>
                {isBurgerMenuOpen && <div className="burger-backdrop" onClick={handleOutsideClick}></div>}
                <div className="burger-icon" onClick={toggleBurgerMenu}>
                    <img src={burgerIcon} alt="Burger Icon" />
                </div>
            </div>
            <div className="downside-header">
            <Link to='/'>
                <div className="logo">
                    <img src={logo} alt="" />
                    <div className="logo-descr">
                        <h1>ооо априори</h1>
                        <p>Чистота во всем</p>
                    </div>
                </div>
                </Link>
                <div className="nav-order">
                    <ul className="navmenu">
                        <NavLink to="/"><li>Главная</li></NavLink>
                        <NavLink to="/catalog/" ref={catalogueRef} onMouseEnter={handleCatalogueHover} onMouseLeave={handleCatalogueLeave}><li id='catalogue-btn'>Каталог</li></NavLink>
                        {/* <NavLink to="/about/"><li>О нас</li></NavLink> */}
                        <li className='__click' onClick={scrollToFooter} id='contacts'>Контакты</li>
                    </ul>

                    <div onClick={showSendWindow} className="order-call">
                        <p>заказать звонок</p>
                    </div>
                    <div className="order-call-dropmenu">
                    </div>
                </div>
            </div>
            
            

        </header>
    );
}
