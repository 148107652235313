import React from 'react';


export default function NotFound() {
  function goBack() {
    window.history.back();
  }

  return (
    <main className='not-found-page'>
      <div className='not-found-page-cont'>
        <h1>404</h1>
        <h2>Страница не найдена</h2>
        <p>Извините, мы не смогли найти страницу, которую вы искали.</p>
        <p>Вы можете <span onClick={goBack}>вернуться назад</span> или <a href='/'>на главную</a>.</p>
    </div>
    </main>
  );
}

