import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main/main.css';
import './styles/catalog/catalog.css'
import './styles/others/notfound.css'
import './styles/main/main-adaptive-bigscreen.css'
import './styles/main/main-adaptive-laptop.css'
import './styles/main/main-adaptive-tablet.css'
import './styles/main/main-adaptive-mobile.css'
import './styles/catalog/catalog-adaptive.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('apriori-snab'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);


