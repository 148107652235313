import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CatalogSidebar from './CataloguePage/CatalogSidebar';
import CatalogElem from './CataloguePage/CatalogElem';
import { ServerAddres } from '../App';

export default function Catalog(props) {
  const { category } = useParams();
  const [catalogList, setCatalogList] = useState([]);
  const [serverConnected, setServerConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${ServerAddres}/products`)
      .then(response => {
        if (response.ok) {
          setServerConnected(true);
          return response.json();
        } else {
          setServerConnected(false);
          return [];
        }
      })
      .then(data => {
        setCatalogList(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Ошибка подключения к серверу:', error);
        setServerConnected(false);
        setIsLoading(false);
      });
  }, []); // Пустой массив зависимостей означает, что useEffect сработает только при монтировании компонента

  // Массив приоритетов брендов
  const brandPriority = {
    "wellland": 1,
    "artred": 2,
    'novelhim': 3,
  };

  // Функция для сортировки по приоритету бренда и по алфавиту по параметру 'model'
  const sortByBrandAndPrice = (a, b) => {
    if (a.type === 'detergents' && b.type === 'detergents') {
      return a.individual.number - b.individual.number;
    }
    if (brandPriority[a.brand] < brandPriority[b.brand]) return -1;
    if (brandPriority[a.brand] > brandPriority[b.brand]) return 1;
    return a.price - b.price;
  };

  // Функция для сортировки по типу массива
  const sortByType = (a, b) => {
    const typeOrder = ['scrubbers','hp-washers', 'vacuums', 'detergents', 'food-equipment','ge-lawn-mower','ge-snow-blower','dezkovriki'];
    if (typeOrder.indexOf(a.type) < typeOrder.indexOf(b.type)) return -1;
    if (typeOrder.indexOf(a.type) > typeOrder.indexOf(b.type)) return 1;
    return 0;
  };

  // Отсортированный массив
  let sortedCatalog = [...catalogList];

  if (category) {
    if (category === 'scrubbers-wellland') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'scrubbers' && item.brand === 'wellland');
    } else if (category === 'scrubbers-artred') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'scrubbers' && item.brand === 'artred');
    }else if (category === 'for-rent') {
      sortedCatalog = sortedCatalog.filter(item => item.id === 2 || item.id === 4 || item.id === 15);
    } else if (category === 'food-industry') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'detergents' && item.individual.chemType === 'food-industry');
    } else if (category === 'cleaning') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'detergents' && item.individual.chemType === 'cleaning');
    } else if (category === 'industrial') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'detergents' && item.individual.chemType === 'industrial');
    } else if (category === 'food-equipment') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'food-equipment');
    } else if (category === 'high-pressure-washers') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'hp-washers');
    } else if (category === 'hp-apparatus') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'hp-washers' && item.individual.type === 'apparatus');
    } else if (category === 'hp-accessories') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'hp-washers' && item.individual.type === 'accessories');
    } else if (category === 'garden-equipment') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'ge-lawn-mower' || item.type === 'ge-lawn-blower');
    }else if (category === 'vacuum-cleaners') {
      sortedCatalog = sortedCatalog.filter(item => item.type === 'vacuums');
    }else {
      sortedCatalog = sortedCatalog.filter(item => item.type === category);
    }
  }

  // Сортировка по типу массива, приоритету бренда и по алфавиту по параметру 'model'
  sortedCatalog.sort((a, b) => sortByType(a, b) || sortByBrandAndPrice(a, b));

  return (
    <main className='catalog-page'>
      <div className='catalog'>
        <CatalogSidebar />
        <div className='catalog-cont'>
          {isLoading ? (
            <div className='loading'>
              <h1>Загрузка...</h1>
            </div>
          ) : serverConnected ? (
            sortedCatalog.length === 0 ? (
              <div className='catalog-not-found'>
                <h1>Данный раздел пока не доступен</h1>
              </div>
            ) : (
              sortedCatalog.map(item => (
                <CatalogElem key={item.id} id={item.id} category={category}/>
              ))
            )
          ) : (
            <div className='server-not-connected'>
              <h1>Не удалось подключиться к серверу</h1>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
