import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { catalog_descriptions } from '../../arrays/catalog-descriptions';
import switch_catalog_type from '../../utils/catalog_switch_type';
import NotFound from '../NotFound';
import { ServerAddres } from '../../App';
import LoadingPlaceholder from './LoadingPlaceholder';

export default function CatalogElemPage() {
  const [catalog_list, setCatalogList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [descrType, setDescrType] = useState('specifications'); // Состояние для отслеживания выбранного типа отображения
  const [specView, setSpecView] = useState(true); // Состояние для отслеживания отображения блока с характеристиками
  const [currentImg,setCurrentImg] = useState(0)
  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  let hidden_main_indivs = false
  
  useEffect(() => {
    // Загрузка данных с сервера
    fetch(`${ServerAddres}/products`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Не удалось загрузить каталог товаров');
        }
      })
      .then(data => {
        setCatalogList(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Ошибка загрузки каталога товаров:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Функция для обновления ширины изображения
    const updateImageWidth = () => {
      if (imageRef.current) {
        // Используем requestAnimationFrame, чтобы убедиться, что рендер завершен
        requestAnimationFrame(() => {
          setImageWidth(imageRef.current.offsetWidth);
        });
      }
    };
  
    // Обновляем ширину при изменении размеров окна
    window.addEventListener('resize', updateImageWidth);
  
    // Устанавливаем ширину изображения при первом рендере
    updateImageWidth();
  
    // Удаляем обработчик события при размонтировании компонента
    return () => window.removeEventListener('resize', updateImageWidth);
  }, [currentImg]); // Обновляем ширину изображения при изменении currentImg
  

  const { item } = useParams();
  const item_id = item.split('__')[0];
  const catalog_elem = catalog_list.find((item) => item.id.toString() === item_id);
  

  if (!catalog_elem && !loading) {
    return <NotFound />;
  }
  
    
  const catalog_elem_type = switch_catalog_type(catalog_elem?.type);
  const elem_catalog_description = catalog_descriptions.find((item) => item.type === catalog_elem?.type);


  const handleCurrImg = (direction) => {
    setCurrentImg((prevImg) => {
      const newImg = prevImg + direction;
      if (newImg < 0) {
        return catalog_elem.image.length - 1; // Переход к последнему изображению
      } else if (newImg >= catalog_elem.image.length) {
        return 0; // Переход к первому изображению
      } else {
        return newImg;
      }
    });
  };
  
  
  

  const selectImage = (index) => {
    setCurrentImg(index)
}

  const showSendWindow = () => {
    document.querySelector('.call-order-menu').style.display = 'flex';
    document.querySelector('.call-order-menu-bg').style.display = 'flex';
    document.querySelector('.call-order-menu p').innerHTML =
      'Оставьте заявку, и наши менеджеры скоро свяжутся с Вами!';
  };

  function goBack() {
    window.history.back();
  }

  const catalog_switch_descr = () => {
    if (!catalog_elem.description) {
      return null;
    }

    return (
      <div className='catalog-switch-cont'>
        <div className='catalog-page-descr-cont'>
          <p>{catalog_elem.description}</p>
        </div>
      </div>
    );
  };
  
  const catalog_switch_specifications = () => {
    // Проверяем наличие описания для типа товара
    if (!elem_catalog_description) {
      setDescrType('descr');
      setSpecView(false);
      return null;
    }
  
    // Проверяем условия для отображения характеристик
    if ((!catalog_elem.individual || Object.keys(catalog_elem.individual).length <= 1) && (!catalog_elem.variations || catalog_elem.variations.length === 0)) {
      setDescrType('descr');
      setSpecView(false);
      return null;
    }
  
    return (
      <div className='catalog-switch-cont'>
        {(!catalog_elem.individual || Object.keys(catalog_elem.individual).length <= 1) ? null : (
          <div className='catalog-page-spec-cont'>
            <h3 style={{marginLeft: '10px'}}>Характеристики:</h3>
            <div className='catalog-spec-elems-cont'>
              {Object.entries(elem_catalog_description).map(([key, description]) => {
                if (key === 'type' || !catalog_elem.individual[key]) {
                  return null;
                }
  
                // Если ключ - цвет, отображаем квадратики цветов
                if (key === 'isColor') {
                  return Object.entries(catalog_elem.individual)
                    .filter(([colorKey, colorValue]) => colorKey.startsWith('is') && colorValue === 1)
                    .map(([colorKey]) => {
                      const colorName = colorKey.slice(2);
                      return (
                        <div key={colorKey} className='catalog-spec-elem'>
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: colorName.toLowerCase(),
                              marginRight: '5px',
                            }}
                          ></div>
                          <p>{colorName}</p>
                        </div>
                      );
                    });
                }
  
                // Иначе, отображаем стандартную характеристику
                const value = catalog_elem.individual[key];
                return (
                  <div className='catalog-spec-elem' key={key}>
                    <p>{description}</p>
                    <strong>{catalog_elem.individual[key] === 'acid' ? 'Кислотное' : catalog_elem.individual[key] === 'base' ? 'Щелочное' : catalog_elem.individual[key] === 'neutral' ? 'Нейтральное' : catalog_elem.individual[key] === 'chlor-base' ? 'Щелочное с хлором' : value}</strong>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
  

  const catalog_switch_variations = () => {
    if (!catalog_elem.variations || catalog_elem.variations.length === 0) {
      return null;
    }
    return (
      <div className='catalog-page-spec-cont'>
        <h3>Варианты товара:</h3>
        <div className='catalog-spec-elems-cont'>
          {catalog_elem.variations.map((variation, index) => (
            <div key={index} className='catalog-spec-elem food-equip-elems'>
              {variation.art && (
              <div className="food-equip-elems-cont">
                <p>Артикул</p>
                <strong>{variation.art}</strong>
              </div>
              )}
              <div className="food-equip-elems-cont">
                <p>Тип</p>
                <strong>{variation.var}</strong>
              </div>
              <div className="food-equip-elems-cont">
                <p>Цена</p>
                <strong>{variation.price} руб.</strong>
              </div>
              
              {variation.colors && variation.colors.length > 0 && (
                <div className="food-equip-elems-cont colors-equip-elems-cont">
                  <p>Цвета</p>
                  <div className='catalog-colors-cont-food'>
                    {variation.colors.map((color, idx) => (
                      <div key={idx} className='catalog-color'>
                        <div className='color-var-food-equip'
                          style={{
                            backgroundColor: color.toLowerCase(),
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (

    <main className='catalog-page'>
      
      <div className='catalog-page-cont'>
        <p className='goBackButton' onClick={goBack}>
          Вернуться назад
        </p>
        <h1 className='catalog-title'>
              {catalog_elem_type} {catalog_elem.brand !== 'riwarus' ? catalog_elem.brand : null} {catalog_elem.model}
            </h1>
        
        <div className='catalog-page-top-elem'>
          <div className='product-img-cont'>
            {/*Проверка если одно изображение (Устаревшая функция) */}
            { typeof catalog_elem.image === "string" && (
            <img
              src={catalog_elem.image}
              alt=''
              style={
                catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'acid'
                  ? { filter: 'hue-rotate(142deg) saturate(1.2)' }
                  : catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'neutral'
                  ? { filter: 'saturate(0)' }
                  : {}
              }
            />
            )}
            {/*Проверка если одно изображение и это массив*/}
            { (typeof catalog_elem.image === "object" && catalog_elem.image.length === 1) && (
            <img
              src={catalog_elem.image[0]}
              alt=''
              style={
                catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'acid'
                  ? { filter: 'hue-rotate(142deg) saturate(1.2)' }
                  : catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'neutral'
                  ? { filter: 'saturate(0)' }
                  : {}
              }
            />
            )}
            {/*Проверка если несколько изображений */}
            {(typeof catalog_elem.image === 'object' && catalog_elem.image.length > 1) && (
              <div className='multi-image'>
              <div className="bigImage">
                <div className="leftArrow" onClick={() => handleCurrImg(-1)}>
                  <svg width="18" height="35" viewBox="0 0 18 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.222 33.5269L1.02929 17.3342C0.990238 17.2951 0.990236 17.2318 1.02929 17.1927L17.222 1" stroke="#000" strokeWidth="1.5" strokeLinecap="round"></path>
                  </svg>
                </div>
                <div className="image-cont">
                  {catalog_elem.image.map((image, index) => (
                    <img
                      key={index}
                      className='BigImage'
                      src={image}
                      alt=""
                      style={{ transform: `translateX(-${imageWidth * currentImg}px)` }}
                      ref={index === currentImg ? imageRef : null} // Установите реф для текущего изображения
                    />
                  ))}
                </div>
                <div className="rightArrow" onClick={() => handleCurrImg(1)}>
                  <svg style={{ transform: "rotate(180deg)" }} width="18" height="35" viewBox="0 0 18 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.222 33.5269L1.02929 17.3342C0.990238 17.2951 0.990236 17.2318 1.02929 17.1927L17.222 1" stroke="#000" strokeWidth="1.5" strokeLinecap="round"></path>
                  </svg>
                </div>
              </div>
              <div className='image-chooser'>
                <div className='mini-images'>
                  {catalog_elem.image.map((image, index) => (
                    <img
                      key={index}
                      style={index === currentImg ? { filter: 'opacity(1)' } : {}}
                      src={image}
                      alt=""
                      className='mini-imgs'
                      onClick={() => selectImage(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
            )}
            {catalog_elem.type === 'detergents' && (
              <p
                style={
                  catalog_elem.individual.substanceType === 'acid'
                    ? { filter: 'hue-rotate(142deg) saturate(1.2)' }
                    : catalog_elem.individual.substanceType === 'chlor-base'
                    ? { color: '#fade00' }
                    : catalog_elem.individual.substanceType === 'neutral'
                    ? { filter: 'saturate(0)' }
                    : {}
                }
              >
                {catalog_elem.individual.number}
              </p>
            )}
          </div>
          <div className='catalog-page-main-info'>
            
            {catalog_elem.price ? <h2>{catalog_elem.price} руб.</h2> : null}
            {catalog_elem.rentprice ? <h2>Аренда от {catalog_elem.rentprice} руб/мес.</h2> : null}
            {catalog_elem.title ? <h3>{catalog_elem.title}</h3> : null}
            <button onClick={showSendWindow}>Оставить заявку</button>
            
            <div className='main-indivs'>

              {catalog_elem.type === 'food-equipment' || (catalog_elem.type === 'hp-washers' && catalog_elem.individual.type) === 'accessories' ? hidden_main_indivs = true : hidden_main_indivs = false /*Скрывать поле основных характеристик*/}
              {!hidden_main_indivs && (<p>Основные характеристики:</p>)}
              {/*Если Поломоечные машины */}
                {catalog_elem.type !== 'scrubbers' ? null : (
                  <ul className='scrubbers-main-indivs'>
                    <li>Производительность: {catalog_elem.individual.efficiency} м2/ч </li>
                    <li>Рабочая ширина: {catalog_elem.individual.workWidthBrushes} мм</li>
                    <li>Объем бака для чистой воды: {catalog_elem.individual.cleanWaterVolume} л </li>
                    <li>Объём бака для грязной воды: {catalog_elem.individual.dirtyWaterVolume} л </li>
                    <li>Время работы: {catalog_elem.individual.oneChargeTime} ч </li>
                  </ul>
                )}
              {/*Если Аппараты высокого давления */}
                {catalog_elem.type !== 'hp-washers' ? null : (
                  <ul className='scrubbers-main-indivs'>
                    <li>Рабочее давление: {catalog_elem.individual.pressure} бар </li>
                    <li>Производительность: {catalog_elem.individual.efficiency} л/мин </li>
                    <li>{catalog_elem.individual.powerGas ? <>Питание: {catalog_elem.individual.powerGas}</> : <>Электропитание: {catalog_elem.individual.powerElectric} ф/В/Гц</>}</li>
                  </ul>
                )}
              {/*Если Аппараты высокого давления */}
                {catalog_elem.type !== 'detergents' ? null : (
                  <ul className='scrubbers-main-indivs'>
                    <li>Рабочая температура: {catalog_elem.individual.workTemp} °C </li>
                    <li>Время мойки: {catalog_elem.individual.workTime} мин </li>
                    <li>Тип химии: {catalog_elem.individual.substanceType === 'acid' ? 'Кислотное' : catalog_elem.individual.substanceType === 'base' ? 'Щелочное' : catalog_elem.individual.substanceType === 'neutral' ? 'Нейтральное' : 'Щелочное с хлором'}</li>
                    <li>Кислотный показатель pH: {catalog_elem.individual.ph}</li>
                  </ul>
                )}
            </div>
                
          </div>
        </div>
        <div className='catalog-page-descr-elem'>
          <div style={{marginLeft: '10px'}} className='catalog-page-descr-switch'>
            <div
              style={!specView ? { display: 'none' } : {}}
              onClick={() => setDescrType('specifications')}
              className={descrType === 'specifications' ? 'catalog-page-switch-elem __active-switcher' : 'catalog-page-switch-elem'}
            >
              Характеристики
            </div>
            {catalog_elem.type !== 'food-equipment' && catalog_elem.description && (
              <div
                onClick={() => setDescrType('descr')}
                className={descrType === 'descr' ? 'catalog-page-switch-elem __active-switcher' : 'catalog-page-switch-elem'}
              >
                Описание
              </div>
            )}
        </div>
        {descrType === 'specifications' ? catalog_switch_specifications() : null}
        {descrType === 'descr' ? catalog_switch_descr() : null}
        {descrType === 'specifications' || (descrType === 'specifications' && catalog_elem.variations && catalog_elem.variations.length > 0) ? catalog_switch_variations() : null}
      </div>
    </div>
  </main>
);
}