import React, {useState, useEffect} from 'react'
import rightArrow from '../../content/right-arrow.svg'
import cataloguePhoto from '../../content/catalogue-elem-photo.webp'
import { catalogue_description } from '../../arrays/main-description'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import useServerStatus from '../../hooks/useServerStatus'
import { ServerAddres } from '../../App'


export default function MainThird() {
    const isConnect = useServerStatus();
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get(`${ServerAddres}/api/content`)
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const getContentOrFallback = (id, fallback) => {
    if (isConnect) {
      const image = images.find(item => item.id === id);
      if (image) {
        return image.content;
      } else {
        console.error(`Image with id ${id} not found`);
        return fallback;
      }
    } else {
      return fallback;
    }
  };
  return (
    <div className='third-screen-catalogue'>
        <div className="black-line"></div>
        <div className='catalogue-welcome-text'>
            <h2>Что мы предлагаем?</h2>
            <h1>Наш каталог</h1>
        </div>
        <div className="catalogue-screen-list-overcont">
            <div className="catalogue-screen-list">
                {catalogue_description.map((elem, index)=>(
                    <NavLink to={elem.link} className="catalogue-screen-list-element">
                    <img style={elem.title_first === 'дезковрики' ? {filter:'brightness(85%)'}: null} src={getContentOrFallback(`img_main_about_${+index + 8}`, elem.image)} alt="" />

                    <div className="catalogue-screen-list-text-cont">
                        <div className="price-link">
                        <button><NavLink to={elem.link}><img src={rightArrow} alt="" /><div className="button-shadow"></div></NavLink></button>
                        {elem.min_price ? (<p>От {elem.min_price} Рублей</p>) : null}
                        
                    </div>
                    <div className="catalogue-screen-list-descr">
                        <h3>{elem.title_first} <span style={{ background: elem.title_second ? 'black' : 'none' }}>{elem.title_second}</span></h3>
                    </div>
                </div></NavLink>
                ))}
                
        </div>
        </div>
    </div>
  )
}
