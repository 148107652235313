import React, { useState, useEffect } from 'react';
import axios from 'axios';
import im1 from '../../content/first-main-screen.jpeg';
import im2 from '../../content/first-main-screen.jpeg';
import im3 from '../../content/first-main-screen.jpeg';
import im4 from '../../content/first-main-screen.jpeg';
import im5 from '../../content/first-main-screen.jpeg';
import useServerStatus from '../../hooks/useServerStatus';
import { ServerAddres } from '../../App';


export default function MainFirst() {
  const isConnect = useServerStatus();
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get(`${ServerAddres}/api/content`)
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const getContentOrFallback = (id, fallback) => {
    if (isConnect) {
      const image = images.find(item => item.id === id);
      if (image) {
        return image.content;
      } else {
        console.error(`Image with id ${id} not found`);
        return fallback;
      }
    } else {
      return fallback;
    }
  };

  return (
    <div className="first-screen-work">
      <div id="slider">
        <figure>
          <img src={getContentOrFallback('img_main_slider_1', im1)} alt=''/>
          <img src={getContentOrFallback('img_main_slider_2', im2)} alt=''/>
          <img src={getContentOrFallback('img_main_slider_3', im3)} alt=''/>
          <img src={getContentOrFallback('img_main_slider_4', im4)} alt=''/>
          <img src={getContentOrFallback('img_main_slider_5', im5)} alt=''/>
        </figure>
      </div>
      <div className="achivements">
        <div>
          <h1>{getContentOrFallback('text_main_1', '800+')}</h1>
          <h3>Позиций в каталоге</h3>
        </div>
      </div>
      <ul className='advantages'>
        <li>Профессионализм</li>
        <li>Скорость поставки</li>
        <li>Гарантия на оборудование</li>
      </ul>
      <div className="supplies">
        <h1>{getContentOrFallback('text_main_2', 'Комплексные решения')}</h1>
        <h2>{getContentOrFallback('text_main_3', 'чистоты и гигиены')}</h2>
        <p>{getContentOrFallback('text_main_4', 'Импортные и отечественные товары и оборудование для любой отрасли промышленности, сельского хозяйства, социальных и медицинских учреждений')}</p>
      </div>
    </div>
  );
}
