import React, {useState, useEffect} from 'react'
import earth_grid_5 from '../../content/why-we-photos/earth_grid_5.webp'
import earth_grid_51 from '../../content/why-we-photos/earth_grid_51.webp'
import growth_11 from '../../content/why-we-photos/growth_11.webp'
import growth_1 from '../../content/why-we-photos/growth_1.webp'
import workers_18 from '../../content/why-we-photos/workers_18.webp'
import workers_181 from '../../content/why-we-photos/workers_181.webp'
import axios from 'axios'
import useServerStatus from '../../hooks/useServerStatus'
import { ServerAddres } from '../../App'

export default function MainFourth() {
  const isConnect = useServerStatus();
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get(`${ServerAddres}/api/content`)
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const getContentOrFallback = (id, fallback) => {
    if (isConnect) {
      const image = images.find(item => item.id === id);
      if (image) {
        return image.content;
      } else {
        console.error(`Image with id ${id} not found`);
        return fallback;
      }
    } else {
      return fallback;
    }
  };
  
  return (
    <div className='main-why-we'>
      <div className="why-we-cont">
          <div className="main-why-we-text">
            <h2>Преимущества</h2>
            <h1>почему мы</h1>
          </div>
        <div className="main-why-we-causes">
            <div className="why-we-cause-cont">
              <h1>01</h1>
              <div className='why-we-cause'>
                <div className="why-we-cause-img">
                  <img className='why-we-img' src={earth_grid_5} alt="" />
                  <img className='why-we-img-shadow' src={earth_grid_51} alt="" />
                </div>
                <h2>{getContentOrFallback('text_main_10','Бесплатная доставка до вашего предприятия')}</h2>
                <p>{getContentOrFallback('text_main_11',"Мы привезем ваши заказы быстро и аккуратно, обучим ваших сотрудников правилам эксплуатации оборудования")}</p>
              </div>
            </div>
            <div className="why-we-cause-cont">
              <h1>02</h1>
              <div className='why-we-cause'>
                <div className="why-we-cause-img">
                  <img className='why-we-img' src={workers_18} alt="" />
                  <img className='why-we-img-shadow' src={workers_181} alt="" />
                </div>
                <h2>{getContentOrFallback("text_main_12","Бесплатная демонстрация")}</h2>
                <p>{getContentOrFallback("text_main_13","Мы привезем и покажем работу оборудования на вашем предприятии, чтобы вы убедились в качестве")}</p>
              </div>
            </div>
            <div className="why-we-cause-cont">
              <h1>03</h1>
              <div className='why-we-cause'>
                <div className="why-we-cause-img">
                  <img className='why-we-img' src={workers_18} alt="" />
                  <img className='why-we-img-shadow' src={workers_181} alt="" />
                </div>
                <h2>{getContentOrFallback("text_main_14","Бесплатное тестирование")}</h2>
                <p>{getContentOrFallback("text_main_15","На предприятиях пищевой промышленности мы бесплатно протестируем и подберем моющие средства")}</p>
              </div>
            </div>
            <div className="why-we-cause-cont">
              <h1>04</h1>
              <div className='why-we-cause'>
                <div className="why-we-cause-img">
                  <img className='why-we-img' src={growth_1} alt="" />
                  <img className='why-we-img-shadow' src={growth_11} alt="" />
                </div>
                <h2>{getContentOrFallback("text_main_16","Сервисное обслуживание")}</h2>
                <p>{getContentOrFallback("text_main_17","Наши специалисты проведут гарантийное и сервисное обслуживание")}</p>
              </div>
            </div>
            
        </div>
      </div>
    </div>
  )
}
