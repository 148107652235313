import chem_cans from '../content/chem-cans-main.jpg'
import scrubbers from '../content/scrubbers-main.jpg'
import vacuum from '../content/vacuums-main.jpg'
import dezkovriki from '../content/dezkovriki-dezkov-xassp.jpg'
import hpwashers from '../content/hp-main.jpg'
import foodEquip from '../content/food-equip-main.jpg'

const switch_descriptions = [
    "Помочь вам в поддержании чистоты на предприятии с минимальными финансовыми затратами. Наша техника, инвентарь и моющие средства сделают труд ваших сотрудников продуктивным, удобным и экологичным.",
    "Зная о чистоте все, мы щедро делимся своими знаниями и наработками с клиентами, помогая добиться успеха в вопросах санитарии и гигиены",
    "Мы успешно сотрудничаем с крупными региональными и федеральными компаниями, подбирая технику и моющие средства взамен ушедших с рынка марок, не ухудшая, а даже улучшая качество чистки поверхностей"
]
const catalogue_description = [
    {
        title_first:'поломоечные',
        title_second:'машины',
        link:'/catalog/scrubbers/',
        min_price:'185.000',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing0 elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: scrubbers,
    },
    {
        title_first:'Моющие',
        title_second:'Средства',
        link:'/catalog/detergents/',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image:  chem_cans,
    },
    {
        title_first:'Аппараты',
        title_second:'высокого давления ',
        link:'/catalog/high-pressure-washers/',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: hpwashers,
    },
    {
        title_first:'пылесосы',
        link:'/catalog/vacuum-cleaners/',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: vacuum,
    },
    {
        title_first:'профессиональный',
        title_second:'пищевой инвентарь',
        min_price:160,
        link:'/catalog/food-equipment/',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: foodEquip,
    },
    {
        title_first:'дезковрики',
        link:'/catalog/food-equipment/',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: dezkovriki,
    },

]

export {switch_descriptions, catalogue_description}