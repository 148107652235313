import React, {useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import axios from 'axios';
import useServerStatus from '../hooks/useServerStatus';
import { ServerAddres } from '../App';


export default function Footer() {
  let showSendWindow = ()=>{
    document.querySelector('.call-order-menu').style.display='flex'
    document.querySelector('.call-order-menu-bg').style.display='flex'
    const call_text = document.getElementById('call-order-info-text')
    call_text.innerHTML = 'Оставьте заявку, и наши менеджеры скоро свяжутся с Вами!';
  }
  const isConnect = useServerStatus();
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get(`${ServerAddres}/api/content`)
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const getContentOrFallback = (id, fallback) => {
    if (isConnect) {
      const image = images.find(item => item.id === id);
      if (image) {
        return image.content;
      } else {
        console.error(`Image with id ${id} not found`);
        return fallback;
      }
    } else {
      return fallback;
    }
  };
  return (
    <footer id='footer'>
        <div className="first-col">
          <div className="upside-row">
            <ul>
              <li><NavLink to='/'>Главная</NavLink></li>
              <li><NavLink to='/catalog'>Каталог</NavLink></li>
              {/* <li><NavLink to='/about'>О нас</NavLink></li>
              <li><NavLink to='/contacts'>Контакты</NavLink></li> */}
            </ul>
          </div>
          <div className="downside-row">
              <h1>наш <span>офис</span> в рязани</h1>
              <YMaps>
                <Map className='yandMaps' defaultState={{ center: [54.625239, 39.792232], zoom: 15 }}><Placemark geometry={[54.625239, 39.792232]} /></Map>
              </YMaps>
          </div>
        </div>
        <div className="second-col">
          <h1>{getContentOrFallback('text_main_18')}</h1>
          <p>{getContentOrFallback("text_main_19","Мы поможем вам подобрать оборудование, комплектующие, инвентарь, моющие и дезинфицирующие средства.")}</p>
          <div className="button-call">
            <button onClick={showSendWindow}>Заказать звонок прямо сейчас</button>
            <div className="btn-shadow"> </div>
          </div>
          <div className="contacts">
            
            <h3>Адрес:</h3>
            <p>Г. РЯЗАНЬ, УЛ. БЫСТРЕЦКАЯ Д. 10</p>
            <div className="phone-copy">
              <div className="phone">
                <h3>Телефон:</h3>
              <p>+7 (915) 600-35-11</p>
              <h3 id='addres-footer'>Адрес электронной почты</h3>
              <p>apriori-dir@mail.ru</p>
              <h3 id='addres-footer'>График работы:</h3>
              <p>9.00-18.00 ПН-ПТ</p>
              </div>
              <div className="copy">
                <p>ООО АПРИОРИ</p>
                <p>ИНН: 6200001228</p>
                <p>ОГРН: 1236200010617</p>
              </div>
            </div>
              
          </div>
        </div>
    </footer>
  )
}
