
export default function switch_catalog_type(catalog_elem){
    let elem_type
    switch (catalog_elem) {
        case 'scrubbers':
            elem_type = 'Поломоечная машина';
            break;
        case 'high-pressure-washers' || 'hp-accessories' || 'hp-apparatus':
            elem_type = 'Мойка высокого давления';
            break;
        default:
            elem_type = '';
            break;
    }
    return elem_type
}

