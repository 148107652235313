import React, { useState, useEffect } from 'react';
import {switch_descriptions} from '../../arrays/main-description'
import gear from '../../content/main-about-1.jpg'
import drill from '../../content/main-about-2.jpg'
import useServerStatus from '../../hooks/useServerStatus';
import axios from 'axios';
import { ServerAddres } from '../../App';


export default function MainSecond() {
  const [descrType, setDescrType] = useState('mission');
  const handleButtonClick = (type) => {
    setDescrType(type);
  };

  const isConnect = useServerStatus();
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get(`${ServerAddres}/api/content`)
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const getContentOrFallback = (id, fallback) => {
    if (isConnect) {
      const image = images.find(item => item.id === id);
      if (image) {
        return image.content;
      } else {
        console.error(`Image with id ${id} not found`);
        return fallback;
      }
    } else {
      return fallback;
    }
  };
  
  return (
    <div className='second-screen-about'>
      <div className="main-about-cont">
        <div className="description">
          <h2>{getContentOrFallback('text_main_5', 'МЫ - ЛУЧШИЕ В СВОЕМ ДЕЛЕ')}</h2>
          <h1>О компании</h1>
          <p>{getContentOrFallback('text_main_6','Мы - команда профессионалов и мы знаем о чистоте все. Наши клиенты - клининговые и управляющие компании, промышленные предприятия, заводы по производству молочной и мясной продукции, агрохолдинги и животноводческие комплексы. Для каждой из этих отраслей мы подбираем индивидуальные решения, начиная от технического оснащения до подбора моющего средства.')} </p>
        </div>
        <div className='switch-description'>
          <div className="descr-buttons">
            <button
              id='__mission'
              className={descrType === 'mission' ? '_active-description' : '_non-active-description'}
              onClick={() => handleButtonClick('mission')}
            >
              Наша миссия
            </button>
            <button
              id='__targets'
              className={descrType === 'targets' ? '_active-description' : '_non-active-description'}
              onClick={() => handleButtonClick('targets')}
            >
              наши цели
            </button>
            <button
              id='__achivements'
              className={descrType === 'achivements' ? '_active-description' : '_non-active-description'}
              onClick={() => handleButtonClick('achivements')}
            >
              наши достижения
            </button>
          </div>
          <p>
            {descrType === 'mission' && getContentOrFallback('text_main_7', switch_descriptions[0])}
            {descrType === 'targets' && getContentOrFallback('text_main_8', switch_descriptions[1])}
            {descrType === 'achivements' && getContentOrFallback('text_main_9', switch_descriptions[2])}
          </p>
        </div>
      </div>
      <div className="main-about-photos">
        <div className="about-photos-cont">
            <img className='ms-drill-img' src={getContentOrFallback('img_main_about_6', drill)} alt="" />
            <div className='ms-gear-img-cont'  alt="">
                <img src={getContentOrFallback('img_main_about_7', gear)} alt="" />
                <div></div>
            </div>

        </div>
        
      </div>
    </div>
  );
}
