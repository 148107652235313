import React from 'react';
import './LoadingPlaceholder.css';

const LoadingPlaceholder = () => {
    return (
        <div className="loading-page">
            <div className="loading-img"></div>
            <div className="loading-info">
                <div className="loading-title"></div>
                <div className="loading-price"></div>
                <div className="loading-description"></div>
                <div className="loading-button"></div>
            </div>
        </div>
    );
};

export default LoadingPlaceholder;
