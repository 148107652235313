import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import switch_catalog_type from '../../utils/catalog_switch_type';
import { ServerAddres } from '../../App';

export default function CatalogElem(props) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch(`${ServerAddres}/products`)
            .then(response => response.json())
            .then(data => {
                setProducts(data);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            });
    }, []);

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const catalog_elem = products.find((elem) => elem.id === props.id);

    if (!catalog_elem) {
        return null;
    }

    let elem_type = switch_catalog_type(catalog_elem.type);

    const catalogLink = `/catalog/product/${encodeURIComponent(`${catalog_elem.id}__${catalog_elem.brand}__${catalog_elem.model}`)}`;

    return (
        <div className='catalog-elem'>
            <NavLink to={catalogLink}>
                <div className="img-cont">
                    <img
                        src={typeof catalog_elem.image === 'string' ? catalog_elem.image : catalog_elem.image[0]}
                        alt=""
                        style={
                            catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'acid'
                                ? { filter: 'hue-rotate(142deg) saturate(1.2)' }
                                : catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'neutral'
                                ? { filter: 'saturate(0)' }
                                : null
                        }
                    />
                    {catalog_elem.type === 'detergents' && catalog_elem.individual.number ? (
                        <p
                            style={
                                catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'acid'
                                    ? { filter: 'hue-rotate(142deg) saturate(1.2)' }
                                    : catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'chlor-base'
                                    ? { color: '#fade00' }
                                    : catalog_elem.type === 'detergents' && catalog_elem.individual.substanceType === 'neutral'
                                    ? { filter: 'saturate(0)' }
                                    : null
                            }
                        >
                            {catalog_elem.individual.number}
                        </p>
                    ) : null}
                </div>
                <p>{elem_type}</p>
                <p>{catalog_elem.brand !== 'riwarus' ? catalog_elem.brand : null} {catalog_elem.model}</p>
                {catalog_elem.type === 'scrubbers' ? (
                    <div className='scrubbers-info'>
                        <div>
                            <p>Рабочая ширина</p>
                            <p><strong>{catalog_elem.individual.workWidthBrushes} мм</strong></p>
                        </div>
                        <div>
                            <p>Производительность</p>
                            <p><strong>{catalog_elem.individual.efficiency} м²/ч</strong></p>
                        </div>
                    </div>
                ) : null}
                {(catalog_elem.price && props.category !== "for-rent") ? <h3>{formatPrice(catalog_elem.price)} руб.</h3> : null}
                {(catalog_elem.rentprice && props.category === "for-rent") ? <h3>от {formatPrice(catalog_elem.rentprice)} руб/мес.</h3> : null}
                {catalog_elem.title ? <p id='catalog-elem-title'>{catalog_elem.title}</p> : null}
            </NavLink>
        </div>
    );
}
