import React from 'react'
import { NavLink } from 'react-router-dom'

export default function CatalogSidebar() {
  return (
    <div className='catalog-sidebar'>
        <NavLink to='/catalog/' className='catalog-sidebar-elem'>
          <h3>Каталог</h3>
        </NavLink>
        <NavLink to='/catalog/scrubbers/' className='catalog-sidebar-elem'>
          <p>Поломоечные машины</p>
        </NavLink>
            <NavLink to='/catalog/scrubbers-wellland/' className='catalog-sidebar-subelem'>
              <p>Wellland</p>
            </NavLink>
            <NavLink to='/catalog/scrubbers-artred/' className='catalog-sidebar-subelem'>
              <p>Artred</p>
            </NavLink>
            <NavLink to='/catalog/for-rent/' className='catalog-sidebar-subelem'>
              <p>Аренда</p>
            </NavLink>

        <NavLink to='/catalog/high-pressure-washers/' className='catalog-sidebar-elem'>
          <p>Мойки высокого давления</p>
        </NavLink>
            <NavLink to='/catalog/hp-apparatus/' className='catalog-sidebar-subelem'>
              <p>Аппараты высокого давления</p>
            </NavLink>
            <NavLink to='/catalog/hp-accessories/' className='catalog-sidebar-subelem'>
              <p>Комплектующие для АВД</p>
            </NavLink>

        <NavLink to='/catalog/vacuum-cleaners/' className='catalog-sidebar-elem'>
          <p>Пылесосы</p>
        </NavLink>

        <NavLink to='/catalog/detergents/' className='catalog-sidebar-elem'>
          <p>Моющие средства</p>
        </NavLink>
            <NavLink to='/catalog/food-industry/' className='catalog-sidebar-subelem'>
              <p>Пищевая промышленность</p>
            </NavLink>
            <NavLink to='/catalog/cleaning/' className='catalog-sidebar-subelem'>
              <p>Клининг</p>
            </NavLink>
            <NavLink to='/catalog/industrial/' className='catalog-sidebar-subelem'>
              <p>Индустриальная химия</p>
            </NavLink>

        <NavLink to='/catalog/food-equipment/' className='catalog-sidebar-elem'>
          <p>Профессиональный пищевой инвентарь</p>
        </NavLink>


        <NavLink to='/catalog/garden-equipment/' className='catalog-sidebar-elem'>
          <p>Садовая техника</p>
        </NavLink>
            <NavLink to='/catalog/ge-lawn-mower/' className='catalog-sidebar-subelem'>
              <p>Газонокосилки</p>
            </NavLink>
            <NavLink to='/catalog/ge-snow-blower/' className='catalog-sidebar-subelem'>
              <p>Снегоуборщики</p>
            </NavLink>


        <NavLink to='/catalog/dezkovriki/' className='catalog-sidebar-elem'>
          <p>Дезковрики</p>
        </NavLink>
    </div>
  )
}
